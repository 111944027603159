import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';

export function promisifyLoader(
  loader: FBXLoader,
  onProgress: ((event: ProgressEvent<EventTarget>) => void) | undefined
) {
  function promiseLoader(url: string) {
    return new Promise((resolve: (object: THREE.Object3D) => void, reject) => {
      loader.load(url, resolve, onProgress, reject);
    });
  }

  return {
    originalLoader: loader,
    load: promiseLoader,
  };
}
