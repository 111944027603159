import { Vector3 } from 'three';
import { ArMaterial, ArScenarioProject, SubstepAction } from '../buzzcommon';

export enum ActionTypes {
  transform = 'transform',
  axisRotation = 'axisRotation',
  screwUnscrew = 'screw',
}
export enum EffectsTypes {
  hide = 'hide',
  show = 'show',
  flash = 'flash',
}
export enum PointerModes {
  select = 'select',
  move = 'move',
  rotate = 'rotate',
  disabled = 'disabled',
}

export enum SceneElementType {
  collection = 'collection',
  model = 'model',
  mesh = 'mesh',
}

export enum TransformBasis {
  world = 0,
  local,
}

interface IAllowedTransforms {
  select: string[];
  move: string[];
  rotate: string[];
}

export const AllowedTransforms: IAllowedTransforms = {
  select: [
    ActionTypes.axisRotation,
    ActionTypes.screwUnscrew,
    ActionTypes.transform,
  ],
  move: [
    ActionTypes.axisRotation,
    ActionTypes.screwUnscrew,
    ActionTypes.transform,
  ],
  rotate: [ActionTypes.transform],
};

// default objects should not be here
export const defaultAction: SubstepAction = {
  nodeIds: Array<number>(),
  actionType: ActionTypes.transform,
  transform: {
    axisStart: new Vector3(0, 0, 0),
    axisEnd: new Vector3(0, 0, 0),
    scale: new Vector3(1, 1, 1),
    anchorNodeId: 0,
    axisRotation: 0,
    basis: TransformBasis.world,
    center: new Vector3(0, 0, 0),
    centerOffset: new Vector3(0, 0, 0),
    // centerLocal: new Vector3(0, 0, 0),
    rotation: new Vector3(0, 0, 0),
    translation: new Vector3(0, 0, 0),
    screwMove: 0,
    screwTurns: 0,
  },
  transitionParams: new Map([['algo', 'linear']]),
  effects: [
    {
      type: 'none',
      actionParams: new Map([['speed', 'fast']]),
    },
  ],
};

type MetadataSet = Map<string, string>;

export const defaultArScenarioProject: ArScenarioProject = {
  id: 0,
  userId: 0,
  arModels: [],
  description: '',
  fileName: '',
  name: '',
  steps: [],
  isSaving: false,
  materials: new Map<number, ArMaterial>(),
  globalMetadata: {},
  metadata: {},
  uuid: '',
  dimensions: new Vector3(0, 0, 0),
};
