import React, { useState, useEffect } from 'react';

import { urlToFile } from '../../../buzzcommon/utils/BuzzArUtils';
import { SceneElement, Tool } from '../../../buzzcommon';

import styles from './styles.module.scss';
import { StylizedModal, StylizedInput, StylizedButton } from '../../stylized';
import AbsoluteLoader from '../../stylized/AbsoluteLoader/AbsoluteLoader';
import { Vector3 } from 'three';

interface Props {
  object: SceneElement;
  open: boolean;
  getSnapshotByNodeId: (nodeId: number) => string | undefined;
  onClose: () => void;
  addModelToGallery: (nodeId: number, tool: Tool) => Promise<Tool>;
  uploadGalleryItemThumb: (toolId: string, file: File) => Promise<Tool>;
}

const AddToGalleryModal = (props: Props) => {
  const {
    open,
    object,
    onClose,
    addModelToGallery,
    uploadGalleryItemThumb,
    getSnapshotByNodeId,
  } = props;

  const [isToolUploading, setIsToolUploading] = useState(false);
  const [name, setName] = useState(object.name);
  const [thumb, setThumb] = useState('');

  useEffect(() => {
    const snapshot = getSnapshotByNodeId(object.nodeId);

    if (snapshot) setThumb(snapshot);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const onToolUpload = async () => {
    const file = await urlToFile(thumb, `${object.nodeId}.jpeg`, 'image/jpeg');
    if (!file) return;

    setIsLoading(true);

    const tool: Tool = {
      name,
      categoryName: 'Custom',
      uniqueId: '',
      thumb: false,
      custom: true,
      dimensions: new Vector3(0,0,0),
    };

    try {
      const responseTool = await addModelToGallery(object.nodeId, tool);
      await uploadGalleryItemThumb(responseTool.uniqueId, file);
    } catch (e) {
      // TODO: handle errors in uploading tool and thumb
      console.log(e);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <StylizedModal
      open={open}
      onClose={onClose}
      className={styles.addToGalleryModal}
      title="Adding to gallery"
    >
      <div className={styles.body}>
        <StylizedInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles.input}
          inputClass={styles.inputElement}
        />
        {isLoading && <AbsoluteLoader />}

        <img alt= "Adding to gallery" src={thumb} className={styles.thumbImage} />

        <div className={styles.buttons}>
          <StylizedButton
            colorType="default"
            className={styles.button}
            disabled={isToolUploading}
            onClick={onToolUpload}
          >
            Add
          </StylizedButton>

          <StylizedButton
            colorType="secondary"
            className={styles.button}
            onClick={onClose}
            disabled={isToolUploading}
          >
            Close
          </StylizedButton>
        </div>
      </div>
    </StylizedModal>
  );
};

export default AddToGalleryModal;
