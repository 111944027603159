import { ArMaterials, ReducedMaterial, RGBColor } from './types';
import {
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Color,
  Mesh,
  MeshPhysicalMaterial,
  MeshToonMaterial,
  MeshMatcapMaterial,
} from 'three';

import * as THREE from 'three';

interface ArMaterial {
  uuid: string;
  name: string;
  opacity: number;
  transparent: boolean;
  color: { r: number; g: number; b: number };
  thumb?: string;
  metalness?: number;
  roughness?: number;
  emissiveIntensity?: number;
  emissiveColor?: { r: number; g: number; b: number };
}

const formArMaterial = (material: THREE.Material): ArMaterial => {
  const defaultColor = new Color();
  const arMaterial: ArMaterial = {
    uuid: material.uuid,
    name: material.name,
    color: {
      r: defaultColor.r,
      g: defaultColor.g,
      b: defaultColor.b,
    },
    opacity: material.opacity,
    transparent: material.transparent,
  };

  if (material instanceof MeshStandardMaterial) {
    arMaterial.metalness = material.metalness;
    arMaterial.color = {
      r: material.color.r,
      g: material.color.g,
      b: material.color.b,
    };
    arMaterial.emissiveColor = {
      r: material.emissive.r,
      g: material.emissive.g,
      b: material.emissive.b,
    };
    arMaterial.emissiveIntensity = material.emissiveIntensity;
  } else if (material instanceof MeshPhongMaterial) {
    arMaterial.color = {
      r: material.color.r,
      g: material.color.g,
      b: material.color.b,
    };
    arMaterial.emissiveColor = {
      r: material.emissive.r,
      g: material.emissive.g,
      b: material.emissive.b,
    };
    arMaterial.emissiveIntensity = material.emissiveIntensity;
  } else if (material instanceof MeshLambertMaterial) {
    arMaterial.color = {
      r: material.color.r,
      g: material.color.g,
      b: material.color.b,
    };
    arMaterial.emissiveColor = {
      r: material.emissive.r,
      g: material.emissive.g,
      b: material.emissive.b,
    };
    arMaterial.emissiveIntensity = material.emissiveIntensity;
  } else if (material instanceof MeshBasicMaterial) {
    arMaterial.color = {
      r: material.color.r,
      g: material.color.g,
      b: material.color.b,
    };
  }

  return arMaterial;
};

export const getMaterialsFromObject3D = (
  object: THREE.Object3D,
  original: boolean
): ArMaterials => {
  const materials: ArMaterials = {};

  object.traverse((obj: THREE.Object3D) => {
    if (!(obj instanceof Mesh) || obj.type !== 'Mesh') {
      return {};
    }

    // Get materials only for real model mesh objects.
    if (!obj.userData || obj.userData.meshType !== 'object') return {};

    let material = obj.userData.originalMaterial;

    if (!original || !material) {
      material = obj.material;
    }

    if (!material) return {};

    if (Array.isArray(material)) {
      material.forEach((mat) => (materials[mat.uuid] = formArMaterial(mat)));
    } else if (material) {
      materials[material.uuid] = formArMaterial(material);
    }
  });

  return materials;
};

export const getSelectedArMaterials = (
  ids: number[],
  parent: THREE.Object3D
): ArMaterials => {
  let mHash: ArMaterials = {};

  ids.forEach((id) => {
    const object = parent.getObjectById(id);
    if (object) {
      const materials = getMaterialsFromObject3D(object, true);
      if (materials) {
        mHash = { ...mHash, ...materials };
      }
    }
  });

  return mHash;
};

export const createMaterialFromArMaterial = (
  arMaterial: ArMaterial
): THREE.MeshPhysicalMaterial => {
  const material = new MeshPhysicalMaterial({
    metalness: arMaterial.metalness,
    color: new Color(
      arMaterial.color.r,
      arMaterial.color.g,
      arMaterial.color.b
    ),
    roughness: arMaterial.roughness,
    opacity: arMaterial.opacity,
    transparent: arMaterial.transparent,
    emissiveIntensity: arMaterial.emissiveIntensity,
  });

  if (arMaterial.uuid) {
    // Override with project.material.uuid
    material.uuid = arMaterial.uuid;
  } else if (material.uuid) {
    // Set new uuid.
    arMaterial.uuid = material.uuid;
  }

  return material;
};

export const setArMaterialToObject = (
  arMaterial: ArMaterial,
  object: THREE.Object3D
) => {
  const material = createMaterialFromArMaterial(arMaterial);

  if (object instanceof Mesh) {
    object.material = material;

    // TODO: Don't override hardcode. Use originalOverriden material in future.
    object.userData.originalMaterial = material;
  }
};

export const reduceMaterial = (
  material: THREE.Material
): ReducedMaterial | null => {
  let reducedMaterial: ReducedMaterial | null = null;
  let color: RGBColor | null = null;
  let emissive: RGBColor | null = null;
  let emissiveIntensity: number = 0;
  let metalness: number = 0;
  let morphNormals = false;
  let morphTargets = false;
  let refractionRatio: number = 0;
  let roughness: number = 0;
  let skinning = false;
  let vertexTangets = false;
  let wireframe = false;
  let wireframeLinewidth = 0;

  if (material instanceof MeshStandardMaterial) {
    color = {
      r: material.color.r,
      g: material.color.g,
      b: material.color.b,
    };
    emissive = {
      r: material.emissive.r,
      g: material.emissive.g,
      b: material.emissive.b,
    };
    emissiveIntensity = material.emissiveIntensity;
    metalness = material.metalness;
    //morphNormals = material.morphNormals;
    //  morphTargets = material.morphTargets;
    // refractionRatio = material.refractionRatio;
    roughness = material.roughness;
    //vertexTangets = material.vertexTangents;
    wireframe = material.wireframe;
    wireframeLinewidth = material.wireframeLinewidth;
  }

  if (material) {
    reducedMaterial = {
      alphaTest: material.alphaTest,
      blendDst: material.blendDst,
      blendSrcAlpha: material.blendSrcAlpha,
      blendDstAlpha: material.blendDstAlpha,
      blendEquationAlpha: material.blendEquationAlpha,
      clipIntersection: material.clipIntersection,
      clipShadows: material.clipShadows,
      color,
      colorWrite: material.colorWrite,
      depthTest: material.depthTest,
      depthWrite: material.depthWrite,
      dithering: material.dithering,
      emissive,
      emissiveIntensity,
      flatShading: false, //material.flatShading,
      fog: false, //material.fog,
      id: material.id,
      isMaterial: material.isMaterial,
      metalness,
      morphNormals,
      morphTargets,
      name: material.name,
      opacity: material.opacity,
      polygonOffset: material.polygonOffset,
      polygonOffsetFactor: material.polygonOffsetFactor,
      polygonOffsetUnits: material.polygonOffsetUnits,
      premultipliedAlpha: material.premultipliedAlpha,
      refractionRatio,
      roughness,
      skinning,
      stencilMask: 0, //material.stencilMask,
      stencilRef: material.stencilRef,
      stencilWrite: material.stencilWrite,
      toneMapped: material.toneMapped,
      transparent: material.transparent,
      type: material.type,
      uuid: material.uuid,
      vertexColors: material.vertexColors,
      vertexTangents: vertexTangets,
      visible: material.visible,
      wireframe,
      wireframeLinewidth,
    };
  }
  return reducedMaterial;
};

export const prepareMaterialsFromOriginal = (
  originalMaterial: THREE.Material
) => {
  const result = {
    wireframe: new MeshStandardMaterial({
      roughness: 0,
      metalness: 0.4,
      fog: true,
      wireframe: true,
      color: new Color(),
    }),
    transparent: new MeshStandardMaterial({
      roughness: 0,
      metalness: 0.5,
      fog: true,
      wireframe: false,
      color: '#FF0000',
      transparent: true,
      opacity: 0.7,
    }),
    standard: new MeshStandardMaterial({
      roughness: 0,
      metalness: 0.5,
      wireframe: false,
      transparent: false,
    }),
  };
  if (
    originalMaterial instanceof MeshBasicMaterial ||
    originalMaterial instanceof MeshPhongMaterial ||
    originalMaterial instanceof MeshLambertMaterial ||
    originalMaterial instanceof MeshPhysicalMaterial ||
    originalMaterial instanceof MeshStandardMaterial ||
    originalMaterial instanceof MeshToonMaterial ||
    originalMaterial instanceof MeshMatcapMaterial
  ) {
    result.wireframe.color.setHex(originalMaterial.color.getHex());
    result.transparent.color.setHex(originalMaterial.color.getHex());
    result.transparent.opacity = 0.95;

    if (originalMaterial.transparent) {
      result.standard.copy(originalMaterial);
    } else {
      result.standard.opacity = originalMaterial.opacity;
      result.standard.fog = originalMaterial.fog;
      result.standard.color.setHex(originalMaterial.color.getHex());

      if (originalMaterial.alphaMap) {
        result.standard.alphaMap = originalMaterial.alphaMap.clone();
      }
      result.standard.alphaTest = originalMaterial.alphaTest;
    }
  }
  return result;
};
